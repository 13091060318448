import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Production,
  production: true,
  // Replaced by actual value during build
  release: 'bd25a835fab610e24df9b0d52ab3616d78a3d993',

  dashboardUrl: 'https://dashboard.humanmademachine.com',

  apiUrl: 'https://api.humanmademachine.com',

  sentryDsn:
    'https://6504ab8504ec4403bbb00bfca4943b4c@o98546.ingest.sentry.io/6111916',
  sentrySampleRate: 1.0,
};
